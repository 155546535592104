import React from 'react';

import './App.css';

const App = () => {
  return (
    <div className="container">
      <img src="/logo.svg" alt="Dress It Up" className="logo" />
      <a
        href="https://apps.apple.com/mx/app/dress-it-up/id1513674029"
        rel="noreferrer"
        target="_blank"
      >
        <img src="app-store-badge.svg" alt="App Store" />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=io.pixan.dressitup"
        rel="noreferrer"
        target="_blank"
      >
        <img src="google-play-badge.png" alt="Google Play" width="134" />
      </a>
    </div>
  );
};

export default App;
